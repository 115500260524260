/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
import {
  getOneDatabase,
  listDataSimulatorDatabaseAdmin, listFieldsDatabase,
  listSimulatorUserProject,
} from 'api-lofty';

export const byTypeFieldFinder = (field) => {
  if (field.type === 'textrich') {
    return 'fieldDatabaseTextRich';
  } else {
    return 'fieldDatabaseText';
  }
};

export const databaseFieldsGetComponent = (component) => {
  let htmlAdder = '';
  component.forEach((index) => {
    htmlAdder += `
      <div
        data-gjs-resizable="{bc: 1}"
        data-gjs-slugLofty="fieldDatabaseContainer"
        data-gjs-valuelofty="${index.name}"
        data-gjs-name="${index.label}"
        data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
        style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
      >
        <div data-gjs-slugLofty="${byTypeFieldFinder(index)}" data-gjs-name="${index.label} Text" data-gjs-stylable="false" data-gjs-editable="false">
         {{ ${index.name} }}
        </div>
      </div>
    `;
  });
  return htmlAdder;
};

export const readUserFieldData = () => {
  const htmlAdder = `<div
      data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
      data-gjs-resizable="{bc: 1}"
      data-gjs-valuelofty="loftyEmail"
      data-gjs-name="Correo Lofty"
      data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
      style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
    >
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Email Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
        {{ loftyEmail }}
      </div>
    </div><div
      data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
      data-gjs-valuelofty="loftyVerified"
      data-gjs-resizable="{bc: 1}"
      data-gjs-name="Lofty Verificado"
      data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
      style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
    >
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Verified" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
          {{ loftyVerified }}
        </div>
      </div><div
        data-gjs-resizable="{bc: 1}"
        data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
        data-gjs-valuelofty="loftyTypeRegister"
        data-gjs-name="Lofty Tipo de Registro"
        data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
        style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
      >
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Type Register Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
          {{ loftyTypeRegister }}
        </div>
      </div><div
        data-gjs-resizable="{bc: 1}"
        data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
        data-gjs-valuelofty="loftyPassword"
        data-gjs-name="Lofty Contraseña"
        data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
        style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
      >
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Password Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
          {{ loftyPassword }}
        </div>
      </div><div
        data-gjs-resizable="{bc: 1}"
        data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
        data-gjs-valuelofty="loftyName"
        data-gjs-name="Lofty Nombre"
        data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
        style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
      >
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Name Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
          {{ loftyName }}
        </div>
      </div><div
        data-gjs-resizable="{bc: 1}"
        data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
        data-gjs-valuelofty="loftyRole"
        data-gjs-name="Lofty Rol"
        data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
        style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
      >
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Role Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
          {{ loftyRole }}
        </div>
      </div><div
        data-gjs-resizable="{bc: 1}"
        data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
        data-gjs-valuelofty="loftyCreatedAt"
        data-gjs-name="Lofty Creado en"
        data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
        style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
      >
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty CreatedAt Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
          {{ loftyCreatedAt }}
        </div>
      </div>
    `;
  return htmlAdder;
};

export const getFieldsBucket = () => {
  const htmlAdder = `
    <div
      data-gjs-resizable="{bc: 1}"
      data-gjs-slugLofty="fieldBucketDatabaseContainer"
      data-gjs-valuelofty="loftyOriginalName"
      data-gjs-name="Archivo Original"
      data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
      style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
    >
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyOriginalName" data-gjs-name="Archivo Original" data-gjs-stylable="false" data-gjs-editable="false">
        {{ loftyOriginalName }}
      </div>
    </div>
    <div
      data-gjs-resizable="{bc: 1}"
      data-gjs-slugLofty="fieldBucketDatabaseContainer"
      data-gjs-valuelofty="loftyFileName"
      data-gjs-name="Archivo Llave"
      data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
      style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
    >
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyFileName" data-gjs-name="Archivo Llave" data-gjs-stylable="false" data-gjs-editable="false">
        {{ loftyFileName }}
      </div>
    </div>
    <div
      data-gjs-resizable="{bc: 1}"
      data-gjs-slugLofty="fieldBucketDatabaseContainer"
      data-gjs-valuelofty="loftyUrl"
      data-gjs-name="Url"
      data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
      style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
    >
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyUrl" data-gjs-name="URL" data-gjs-stylable="false" data-gjs-editable="false">
        {{ loftyUrl }}
      </div>
    </div>
  `;
  return htmlAdder;
};

export const arrayDataFieldForReadComponent = async (component, token, idProject) => {
  let htmlAdder = '';
  for (const capturedFields of component) {
    const listFieldsFromResponse = await listFieldsDatabase({
      token,
      idDatabase: capturedFields.relationDatabase,
      idProject,
    });
    const typeDB = await getOneDatabase({token, _id: capturedFields.relationDatabase});
    let resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
    if (typeDB.data.isBucket) {
      const bucketFields = getFieldsBucket();
      resFieldsReact += bucketFields;
    }
    htmlAdder = `
      <div data-gjs-slugLofty="fieldArrayDatabaseFieldDatabase" data-gjs-name="Listado de Arreglo" data-gjs-valuelofty="${capturedFields.name}" style="padding: 10px;"  data-gjs-resizable="{bc: 1}">
        <div data-gjs-slugLofty="itemArrayField" data-gjs-name="Item Variable de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
          ${resFieldsReact}
        </div>
      </div>
    `;
  }
  return htmlAdder;
};

export const cartFieldForReadComponent = async (component, token, idProject) => {
  let htmlAdder = '';
  for (const capturedFields of component) {
    const listFieldsFromResponse = await listFieldsDatabase({
      token,
      idDatabase: capturedFields.relationDatabase,
      idProject,
    });
    const typeDB = await getOneDatabase({token, _id: capturedFields.relationDatabase});
    let resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
    if (typeDB.data.isBucket) {
      const bucketFields = getFieldsBucket();
      resFieldsReact += bucketFields;
    }
    htmlAdder = `
      <div data-gjs-slugLofty="fieldCartFieldDatabase" data-gjs-name="Listado de Carrito" data-gjs-valuelofty="${capturedFields.name}" style="padding: 10px;"  data-gjs-resizable="{bc: 1}">
        <div data-gjs-slugLofty="itemCartField" data-gjs-name="Item Variable Carrito" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
          ${resFieldsReact}
          <div data-gjs-slugLofty="quantityItemCartData" data-gjs-name="Cantidad del Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}">
            Cantidad: 
          </div>
        </div>
      </div>
    `;
  }
  return htmlAdder;
};

export const databaseFieldsPostComponent = (component, userInfoData, dataCaptureInfo) => {
  let htmlAdder = '';
  component.forEach((index) => {
    // 'string' | 'number' | 'boolean' | 'date' | 'relation' | 'user';
    if (index.type === 'string' && (!index?.stringDefaultValues?.length > 0)) {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelStringDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <input data-gjs-resizable="{bc: 1}" type="text" data-gjs-slugLofty="formInputDatabase" data-gjs-name="${index.name} Texto" style="background-color: transparent" placeholder="${index.label}" name="${index.name}" `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
        </div>
      `;
    } else if (index.type === 'string' && (index?.stringDefaultValues?.length > 0)) {
      const resStringData = index?.stringDefaultValues;
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldSelectDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelSelectDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <select data-gjs-slugLofty="formSelectAdd" data-gjs-name="${index.name} Seleccion" style="background-color: transparent" name="${index.name}" data-gjs-id="${index._id}"
      `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += '>';
      resStringData?.map((items) => {
        htmlAdder += `
          <option data-gjs-slugLofty="formSelectOptionsAdd" data-gjs-name="${items?.slug} Opcion" value="${items?.slug}">${items?.label}</option>
        `;
      });
      htmlAdder += `
          </select>
        </div>
      `;
    } else if (index.type === 'date') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelDateDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <input data-gjs-resizable="{bc: 1}" type="date" data-gjs-slugLofty="formDateInputDatabase" data-gjs-name="${index.name} Fecha" style="background-color: transparent" value="2018-07-22" name="${index.name}"`;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
        </div>
      `;
    } else if (index.type === 'number') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
        <label data-gjs-slugLofty="formLabelNumberDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <input data-gjs-resizable="{bc: 1}" type="number" data-gjs-slugLofty="formNumberInputDatabase" data-gjs-name="${index.name} Numero" style="background-color: transparent" placeholder="${index.label}"  name="${index.name}"`;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
        </div>
      `;
    } else if (index.type === 'boolean') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelBooleanDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <input type="checkbox" data-gjs-slugLofty="formBooleanInputDatabase" style="background-color: transparent" data-gjs-name="${index.name} Casilla" name="${index.name}"`;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
          <label data-gjs-slugLofty="formLabelCheckbox" data-gjs-name="${index.label} Booleano"> ${index.name} </label>
        </div>
      `;
    } else if (index.type === 'relation') {
      const relationIDSeeker = index.relationDatabase;
      // console.log(dataCaptureInfo[relationIDSeeker].data);
      const selectionData = dataCaptureInfo[relationIDSeeker].data;
      const listFieldsForeignDatabase = dataCaptureInfo[relationIDSeeker].fields;
      const doc = {};
      doc[index.name] = {};
      doc[index.name].data = selectionData;
      doc[index.name].fields = listFieldsForeignDatabase;
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldRelationDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelSelectRelationDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <select data-gjs-slugLofty="formSelectRelation" data-gjs-name="${index.name} Select" name="${index.name}" style="background-color: transparent" data-gjs-id="${index._id}"
      `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += '>';
      // ADD OPTIONS
      doc[index.name]?.data?.map((keyInfo) => {
        let label = '';
        const fields = doc[index.name].fields;
        const tempData = keyInfo.data;
        fields.map((field) => {
          if (field.isLabel) {
            label += `${tempData[field.name]}`;
          }
        });
        htmlAdder += `<option data-gjs-slugLofty="formSelectRelationOption" data-gjs-name="${index.name} Opcion" value="${keyInfo._id}">${label}</option>`;
      });
      htmlAdder += `
          </select>
        </div>
      `;
    } else if (index.type === 'user') {
      const resUserData = userInfoData.data;
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldUserDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelUserDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <select data-gjs-slugLofty="formSelectUser" data-gjs-name="${index.name} Seleccion" style="background-color: transparent" name="${index.name}" data-gjs-id="${index._id}"
      `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += '>';
      resUserData?.map((index) => {
        htmlAdder += `
          <option data-gjs-slugLofty="formSelectUserOption" data-gjs-name="${index.name} Opcion" value="${index._id}">${index.data.loftyEmail}</option>
        `;
      });
      htmlAdder += `
          </select>
        </div>
      `;
    } else if (index.type === 'textrich') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldRichTextDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelRichTextDatabaseS" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <textarea data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="formRichTextDatabase" data-gjs-name="${index.name} Texto Enriquecido" style="background-color: transparent" placeholder="${index.label}" name="${index.name}" `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `></textarea>
        </div>
      `;
    } else if (index.type === 'loftyfile' || index.type === 'loftyFile') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseBucketAddContainer"
          data-gjs-valuelofty="fieldUploadFiletoBucket"
          data-gjs-name="Archivo a subir a Almacenador de Archivos"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelBucketDatabase" data-gjs-name="Etiqueta Subir Archivo" data-gjs-draggable="false">Upload File</label><br/>
          <input type="file" data-gjs-slugLofty="formBucketFileDatabase" data-gjs-name="Entrada para Subir Archivo" style="background-color: transparent" name="fileBucket" />
        </div>
      `;
    }
  });
  return htmlAdder;
};

export const databaseFieldsUpdateComponent = (component, userInfoData, dataCaptureInfo) => {
  let htmlAdder = '';
  component.forEach((index) => {
    // 'string' | 'number' | 'boolean' | 'date' | 'relation' | 'user';
    if (index.type === 'string' && (!index?.stringDefaultValues?.length > 0)) {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseUpdateContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formUpdateLabelStringDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <input data-gjs-resizable="{bc: 1}" type="text" data-gjs-slugLofty="formInputUpdateDatabase" data-gjs-name="${index.name} Texto" style="background-color: transparent" placeholder="${index.label}" name="${index.name}" `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
        </div>
      `;
    } else if (index.type === 'string' && (index?.stringDefaultValues?.length > 0)) {
      const resStringData = index?.stringDefaultValues;
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldSelectDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelSelectDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <select data-gjs-slugLofty="formSelectAdd" data-gjs-name="${index.name} Seleccion" style="background-color: transparent" name="${index.name}" data-gjs-id="${index._id}"
      `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += '>';
      resStringData?.map((items) => {
        htmlAdder += `
          <option data-gjs-slugLofty="formSelectOptionsAdd" data-gjs-name="${items?.slug} Opcion" value="${items?.slug}">${items?.label}</option>
        `;
      });
      htmlAdder += `
          </select>
        </div>
      `;
    } else if (index.type === 'date') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseUpdateContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formUpdateLabelDateDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <input data-gjs-resizable="{bc: 1}" type="date" data-gjs-slugLofty="formDateInputUpdateDatabase" data-gjs-name="${index.name} Fecha" style="background-color: transparent" value="2018-07-22" name="${index.name}"`;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
        </div>
      `;
    } else if (index.type === 'number') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseUpdateContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
        <label data-gjs-slugLofty="formUpdateLabelNumberDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <input data-gjs-resizable="{bc: 1}" type="number" data-gjs-slugLofty="formNumberInputUpdateDatabase" data-gjs-name="${index.name} Numero" style="background-color: transparent" placeholder="${index.label}"  name="${index.name}"`;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
        </div>
      `;
    } else if (index.type === 'boolean') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseUpdateContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formUpdateLabelBooleanDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <input type="checkbox" data-gjs-slugLofty="formBooleanInputUpdateDatabase" style="background-color: transparent" data-gjs-name="${index.name} Casilla" name="${index.name}"`;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
          <label data-gjs-slugLofty="formLabelUpdateCheckbox" data-gjs-name="${index.label} Booleano"> ${index.name} </label>
        </div>
      `;
    } else if (index.type === 'relation') {
      const relationIDSeeker = index.relationDatabase;
      // console.log(dataCaptureInfo[relationIDSeeker].data);
      const selectionData = dataCaptureInfo[relationIDSeeker].data;
      const listFieldsForeignDatabase = dataCaptureInfo[relationIDSeeker].fields;
      const doc = {};
      doc[index.name] = {};
      doc[index.name].data = selectionData;
      doc[index.name].fields = listFieldsForeignDatabase;
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldRelationDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelSelectRelationDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <select data-gjs-slugLofty="formSelectRelation" data-gjs-name="${index.name} Select" name="${index.name}" style="background-color: transparent" data-gjs-id="${index._id}"
      `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += '>';
      // ADD OPTIONS
      doc[index.name]?.data?.map((keyInfo) => {
        let label = '';
        const fields = doc[index.name].fields;
        const tempData = keyInfo.data;
        fields.map((field) => {
          if (field.isLabel) {
            label += `${tempData[field.name]}`;
          }
        });
        htmlAdder += `<option data-gjs-slugLofty="formSelectRelationOption" data-gjs-name="${index.name} Opcion" value="${keyInfo._id}">${label}</option>`;
      });
      htmlAdder += `
          </select>
        </div>
      `;
    } else if (index.type === 'user') {
      const resUserData = userInfoData.data;
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldUserDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelUserDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <select data-gjs-slugLofty="formSelectUser" data-gjs-name="${index.name} Seleccion" style="background-color: transparent" name="${index.name}" data-gjs-id="${index._id}"
      `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += '>';
      resUserData?.map((index) => {
        htmlAdder += `
          <option data-gjs-slugLofty="formSelectUserOption" data-gjs-name="${index.name} Opcion" value="${index._id}">${index.data.loftyEmail}</option>
        `;
      });
      htmlAdder += `
          </select>
        </div>
      `;
    } else if (index.type === 'textrich') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldRichTextDatabaseUpdateContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelRichTextDatabaseUpdate" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
          <textarea data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="formRichTextDatabaseUpdate" data-gjs-name="${index.name} Texto Enriquecido" style="background-color: transparent" placeholder="${index.label}" name="${index.name}" `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `></textarea>
        </div>
      `;
    }
  });
  return htmlAdder;
};

/**
    =========================================================
    * Get Read DB Fields
    =========================================================

    * Gets All the fields from a Read DB Component.
    * Must have verified component before calling,
    * otherwise it will not work.

    =========================================================
  */
export async function getAllReadFieldsForParams(component, token, idProject) {
  const idDatabase = component.attributes.dbSelect;
  const resFieldData = await listFieldsDatabase({idDatabase, idProject, token});
  const selectFields = [];
  resFieldData.data.map((index) => {
    selectFields.push({value: index.name, name: index.label});
  });
  return selectFields;
}

/**
    =========================================================
    * Get Read Relation DB Fields
    =========================================================

    * Gets Relation fields from a Read DB Component.
    * Must have verified component before calling,
    * otherwise it will not work.

    =========================================================
  */
export async function getAllReadRelationFieldsForParams(component, token, idProject) {
  const idDatabase = component.attributes.dbSelect;
  const resFieldData = await listFieldsDatabase({idDatabase, idProject, token});
  const selectFields = [];
  resFieldData.data.forEach((index) => {
    if (index.type === 'relation') {
      selectFields.push({value: index.name, name: index.label});
    }
  });
  return selectFields;
}

/**
    =========================================================
    * Get Read DB Fields for Custom
    =========================================================

    * Gets Valid fields for Custom from a Read DB Component.
    * Must have verified component before calling,
    * otherwise it will not work.

    =========================================================
  */
export async function getAllFieldsForCustomParams(component, token, idProject) {
  const idDatabase = component.attributes.dbSelect;
  const resFieldData = await listFieldsDatabase({idDatabase, idProject, token});
  const selectFields = [];
  resFieldData.data.forEach((index) => {
    if (
      index.type === 'relation' || index.type === 'string' || index.type === 'number'
      || index.type === 'boolean'
    ) {
      selectFields.push({value: index.name, name: index.label, type: index.type});
    }
  });
  return selectFields;
}

export async function getAllFieldsForCartParams(component, token, idProject) {
  const idDatabase = component.attributes.dbSelect;
  const resFieldData = await listFieldsDatabase({idDatabase, idProject, token});
  const selectFields = [];
  resFieldData.data.forEach((index) => {
    if (
      index.type === 'cartArray'
    ) {
      selectFields.push({value: index.name, name: index.label, type: index.type});
    }
  });
  return selectFields;
}

export async function getRelationInfo(relationIDSeeker, token, idProject) {
  const selectionData = await listDataSimulatorDatabaseAdmin({
    token,
    idDatabase: relationIDSeeker,
    idProject,
  });
  return selectionData.data;
}

export async function getForeignFields(relationIDSeeker, token, idProject) {
  const listFieldsForeignDatabase = await listFieldsDatabase({
    token,
    idProject,
    idDatabase: relationIDSeeker,
  });
  return listFieldsForeignDatabase.data;
}

/**
    =========================================================
    * Add Single Field to a DB Component
    =========================================================

    * Creates the HTML of the specified field for a DB component.
    * [For now, just relation is available].

    =========================================================
  */
export async function addSingleField(fieldInfo, type, token, idProject) {
  let retVal = '';
  try {
    if (type === 'relation') {
      const selectionData = await getRelationInfo(fieldInfo._id, token, idProject);
      const listFieldsForeignDatabase = await getForeignFields(fieldInfo._id, token, idProject);
      const doc = {};
      doc[fieldInfo.name] = {};
      doc[fieldInfo.name].data = selectionData;
      doc[fieldInfo.name].fields = listFieldsForeignDatabase;
      let htmlAdder = '';
      htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldRelationDatabaseAddContainer"
                    data-gjs-valuelofty="${fieldInfo.name}"
                    data-gjs-name="${fieldInfo.label}"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <label data-gjs-slugLofty="formLabelSelectRelationDatabase" data-gjs-name="Etiqueta ${fieldInfo.name}" data-gjs-draggable="false">${fieldInfo.label}</label><br/>
                    <select data-gjs-slugLofty="formSelectRelation" data-gjs-name="${fieldInfo.name} Select" name="${fieldInfo.name}" style="background-color: transparent" data-gjs-id="${fieldInfo._id}"
                `;
      if (fieldInfo.required) {
        htmlAdder += 'required';
      }
      htmlAdder += '>';
      // ADD OPTIONS
      doc[fieldInfo.name]?.data?.map((keyInfo) => {
        let label = '';
        const fields = doc[fieldInfo.name].fields;
        const tempData = keyInfo.data;
        fields.map((field) => {
          if (field.isLabel) {
            label += `${tempData[field.name]}`;
          }
        });
        htmlAdder += `<option data-gjs-slugLofty="formSelectRelationOption" data-gjs-name="${fieldInfo.name} Opcion" value="${keyInfo._id}">${label}</option>`;
      });
      htmlAdder += `
                    </select>
                  </div>
                `;
      retVal = htmlAdder;
    } else if (type === 'userfield') {
      // console.log(fieldInfo);
      let htmlAdder = '';
      const userInfoData = await listSimulatorUserProject({token, idProject});
      fieldInfo.forEach((index) => {
        htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldUserDatabaseAddContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <label data-gjs-slugLofty="formLabelUserDatabase" data-gjs-name="Etiqueta ${index.name}" data-gjs-draggable="false">${index.label}</label><br/>
                    <select data-gjs-slugLofty="formSelectUser" data-gjs-name="${index.name} Seleccion" style="background-color: transparent" name="${index.name}" data-gjs-id="${index._id}"
                `;
        if (index.required) {
          htmlAdder += 'required';
        }
        htmlAdder += '>';
        userInfoData?.data.map((index) => {
          htmlAdder += `
                    <option data-gjs-slugLofty="formSelectUserOption" data-gjs-name="${index.name} Opcion" value="${index._id}">${index.data.loftyEmail}</option>
                  `;
        });
        htmlAdder += `
                    </select>
                  </div>
                `;
      });
      retVal = htmlAdder;
    }
    return retVal;
  } catch (error) {
    return null;
  }
}
