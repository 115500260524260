import grapesjs from 'grapesjs';
import loadComponents from './components';
import loadBlocks from './blocks';

export default grapesjs.plugins.add('gjs-cart-item-handler', (editor, opts = {}) => {
  const options = {
    label: 'Manejar Cantidad de Items',
    name: 'cartItemHandler',
    category: 'Extra',
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const name in options) {
    if (!(name in opts)) opts[name] = options[name];
  }
  loadComponents(editor, opts);
  loadBlocks(editor, options);
});
