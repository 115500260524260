/* eslint-disable import/no-anonymous-default-export */
export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .gjs-three-swiper-1 {
    width: 100%;
    height: 300px;
    padding: 10px;
  }
  .gjs-three-swiper-2 {
    width: 100%;
    height: 300px;
    padding: 10px;
  }
  .gjs-three-swiper-3 {
    width: 100%;
    height: 300px;
    padding: 10px;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
    <svg width="40px" height="40px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="icon-lofty-component-block">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 3.5l.5-.5h13l.5.5v9l-.5.5h-13l-.5-.5v-9zM14 4H8v3.493h-.5l-3.574-.005 2.09-2.09-.707-.707-2.955 2.955v.708l2.955 2.955.707-.707-2.114-2.114 3.996.005H8v-.986l3.907.005-2.114-2.114.707-.707 2.956 2.955v.708L10.5 11.309l-.707-.707 2.09-2.09L8 8.507V12h6V4z"/></svg>
    </div>
    <div class="gjs-block-label">
      ${opts.label}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Controla la cantidad de items de un carrito de compras (Solo Componentes de Carrito).', id: 'component-cart-item-handler-lofty-editor'},
    content: `
    <div
      data-gjs-sluglofty="cartItemHandler"
      style="padding: 10px; display: flex; flex-direction: row;" data-gjs-resizable="{bc: 1}"
      data-gjs-name="Controlador de Cantidad de Items"
      data-gjs-droppable="false"
    >
      <div
        data-gjs-sluglofty="reducerItemHandler"
        data-gjs-name="Reducir cantidad"
        style="padding: 10px"
        data-gjs-draggable="false"
      >
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto de Boton Estandar">-</div> 
      </div>
      <div
        data-gjs-sluglofty="quantityItemHandler"
        data-gjs-name="No. Cantidad"
        style="padding: 10px"
        data-gjs-draggable="false"
      >
        <div data-gjs-slugLofty="quantityItemCartData" data-gjs-name="Cantidad del Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}">
          # items 
        </div>
      </div>
      <div
        data-gjs-sluglofty="increaseItemHandler"
        data-gjs-name="Incrementar Cantidad"
        style="padding: 10px"
        data-gjs-draggable="false"
      >
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto de Boton Estandar">+</div>
      </div>
    </div>
  
    ${style}`,
  });
};
