import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-exportpdf-button', (editor, opts = {}) => {
  const exists = opts.plugins?.find((item) => item.type === 'pdfFile');
  if (exists) {
    const config = {
      blocks: [
        'exportpdfButton',
      ],
      name: 'exportpdfButton',
      stylePrefix: 'gjs-',
      labelStandardButton: 'Exportar a PDF',
      defText: 'Exportar',
      category: 'Botones',
      ...opts,
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const name in config) {
      if (!(name in opts)) opts[name] = config[name];
    }
    loadBlocks(editor, config);
    loadComponents(editor, opts);
  }
});
