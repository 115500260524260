// Panel de configuracion de estilos de componentes
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import listFonts from './listFonts';

export const styleManager = {
  appendTo: '#styles-container',
  sectors: [
    {
      name: 'Disposición',
      buildProps: [
        'float',
        'display',
        'position',
        'top',
        'right',
        'left',
        'bottom',
      ],
      properties: [
        {
          name: 'Flotar',
          property: 'float',
          type: 'radio',
          defaults: 'none',
          options: [
            {value: 'none', className: 'fa fa-times', label: 'Ninguno'},
            {value: 'left', className: 'fa fa-align-left', label: 'izquierdo'},
            {value: 'center', className: 'fa fa-align-center', label: 'centro'},
            {value: 'right', className: 'fa fa-align-right', label: 'derecho'},
          ],
        },
        {
          property: 'display',
          type: 'select',
          name: 'Exhibir',
          defaults: 'block',
          options: [
            {
              value: 'block',
              label: 'Bloque',
            },
            {
              value: 'inline',
              label: 'En linea',
            },
            {
              value: 'inline-block',
              label: 'Bloque en linea',
            },
            {
              value: 'none',
              label: 'No mostrar',
            },
          ],
        },
        {
          property: 'position',
          type: 'select',
          name: 'Posición',
          defaults: 'static',
          options: [
            {
              value: 'static',
              label: 'Estático',
            },
            {
              value: 'relative',
              label: 'Relativo',
            },
            {
              value: 'absolute',
              label: 'Absoluto',
            },
            {
              value: 'fixed',
              label: 'Fijado',
            },
          ],
        },
        {property: 'top', name: 'Arriba'},
        {property: 'right', name: 'Derecho'},
        {property: 'left', name: 'Izquierdo'},
        {property: 'bottom', name: 'Abajo'},
        {
          name: 'Z index',
          type: 'integer',
          property: 'z-index',
        },
        {
          name: 'Desbordamiento',
          type: 'select',
          property: 'overflow',
          defaults: 'visible',
          options: [
            {
              label: 'Visible',
              value: 'visible',
            },
            {
              label: 'Ocultar',
              value: 'hidden',
            },
            {
              label: 'Desplazarse',
              value: 'scroll',
            },
            {
              label: 'Auto',
              value: 'auto',
            },
          ],
        },
      ],
    },
    {
      name: 'Alineamiento Flexible',
      open: false,
      properties: [
        {
          name: 'Alineamiento Flexible',
          property: 'display',
          type: 'select',
          defaults: 'block',
          list: [
            {value: 'block', name: 'Deshabilitado'},
            {value: 'flex', name: 'Habilitado'},
          ],
        },
        {
          name: 'Flex Parent',
          property: 'label-parent-flex',
          type: 'integer',
        },
        {
          name: 'Dirección',
          property: 'flex-direction',
          type: 'radio',
          defaults: 'row',
          list: [
            {
              value: 'row',
              name: 'Row',
              className: 'icons-flex icon-dir-row',
              title: 'Fila',
            },
            {
              value: 'row-reverse',
              name: 'Row reverse',
              className: 'icons-flex icon-dir-row-rev',
              title: 'Fila Reverso',
            },
            {
              value: 'column',
              name: 'Column',
              title: 'Columna',
              className: 'icons-flex icon-dir-col',
            },
            {
              value: 'column-reverse',
              name: 'Column reverse',
              title: 'Columna Reverso',
              className: 'icons-flex icon-dir-col-rev',
            },
          ],
        },
        {
          name: 'Empaquetado Flex',
          property: 'flex-wrap',
          type: 'select',
          defaults: 'nowrap',
          list: [
            {value: 'nowrap', name: 'No'},
            {value: 'wrap', name: 'Activado'},
            {value: 'wrap-reverse', name: 'Activado reverso'},
          ],
        },
        {
          name: 'Justificar',
          property: 'justify-content',
          type: 'radio',
          defaults: 'flex-start',
          list: [
            {
              value: 'flex-start',
              className: 'icons-flex icon-just-start',
              title: 'Inicio',
            },
            {
              value: 'flex-end',
              title: 'Final',
              className: 'icons-flex icon-just-end',
            },
            {
              value: 'space-between',
              title: 'Espacio entre',
              className: 'icons-flex icon-just-sp-bet',
            },
            {
              value: 'space-around',
              title: 'Espacio Alrededor',
              className: 'icons-flex icon-just-sp-ar',
            },
            {
              value: 'center',
              title: 'Centro',
              className: 'icons-flex icon-just-sp-cent',
            },
          ],
        },
        {
          name: 'Alinear item',
          property: 'align-items',
          type: 'radio',
          defaults: 'stretch',
          list: [
            {
              value: 'stretch',
              title: 'Estirar',
              className: 'icons-flex icon-al-str',
            },
            {
              value: 'flex-start',
              title: 'Inicio',
              className: 'icons-flex icon-al-start',
            },
            {
              value: 'flex-end',
              title: 'Final',
              className: 'icons-flex icon-al-end',
            },
            {
              value: 'center',
              title: 'Centro',
              className: 'icons-flex icon-al-center',
            },
          ],
        },
        {
          name: 'Alineamiento propio',
          property: 'align-self',
          type: 'radio',
          defaults: 'auto',
          list: [
            {
              value: 'auto',
              name: 'Auto',
            },
            {
              value: 'flex-start',
              title: 'Inicio',
              className: 'icons-flex icon-al-start',
            },
            {
              value: 'flex-end',
              title: 'Final',
              className: 'icons-flex icon-al-end',
            },
            {
              value: 'stretch',
              title: 'Estirar',
              className: 'icons-flex icon-al-str',
            },
            {
              value: 'center',
              title: 'Centro',
              className: 'icons-flex icon-al-center',
            },
          ],
        },
        {
          name: 'Hijos Flex',
          property: 'label-parent-flex',
          type: 'integer',
        },
        {
          name: 'Orden',
          property: 'order',
          type: 'integer',
          defaults: 0,
          min: 0,
        },
        {
          name: 'Flex',
          property: 'flex',
          type: 'composite',
          properties: [
            {
              name: 'Grow',
              property: 'flex-grow',
              type: 'integer',
              defaults: 0,
              min: 0,
            },
            {
              name: 'Shrink',
              property: 'flex-shrink',
              type: 'integer',
              defaults: 0,
              min: 0,
            },
            {
              name: 'Basis',
              property: 'flex-basis',
              type: 'integer',
              units: ['px', '%', ''],
              unit: '',
              defaults: 'auto',
            },
          ],
        },
      ],
    },
    {
      name: 'Dimensiones',
      open: false,
      buildProps: [
        'width',
        'height',
        'max-width',
        'min-height',
        'margin',
        'padding',
      ],
      properties: [
        {
          property: 'width',
          name: 'Anchura',
        },
        {
          property: 'height',
          name: 'Altura',
        },
        {
          property: 'max-width',
          name: 'Anchura máxima',
        },
        {
          property: 'min-height',
          name: 'Altura mínima',
        },
        {
          property: 'margin',
          name: 'Margen',
          properties: [
            {name: 'Superior', property: 'margin-top'},
            {name: 'Derecho', property: 'margin-right'},
            {name: 'Inferior', property: 'margin-bottom'},
            {name: 'Izquierdo', property: 'margin-left'},
          ],
        },
        {
          property: 'padding',
          // name: 'Relleno',
          properties: [
            {name: 'Superior', property: 'padding-top'},
            {name: 'Derecho', property: 'padding-right'},
            {name: 'Inferior', property: 'padding-bottom'},
            {name: 'Izquierdo', property: 'padding-left'},
          ],
        },
      ],
    },
    {
      name: 'Tipografía',
      open: false,
      buildProps: [
        'font-family',
        'font-size',
        'font-weight',
        'letter-spacing',
        'line-height',
        'color',
        'text-align',
        'text-decoration',
        'text-shadow',
      ],
      properties: [
        {
          name: 'Fuente',
          property: 'font-family',
          type: 'select',
          options: listFonts,
        },
        {
          property: 'font-size',
          name: 'Tamaño',
        },
        {
          property: 'letter-spacing',
          name: 'Espacio de letras',
        },
        {
          properties: 'line-height',
          name: 'Altura de salto de linea',
          type: 'number',
        },
        {name: 'Grosor', property: 'font-weight'},
        {name: 'Color de Fuente', property: 'color'},
        {
          name: 'Alinear Texto',
          property: 'text-align',
          type: 'radio',
          defaults: 'left',
          options: [
            {value: 'left', name: 'Left', className: 'fa fa-align-left'},
            {
              value: 'center',
              name: 'Center',
              className: 'fa fa-align-center',
            },
            {value: 'right', name: 'Right', className: 'fa fa-align-right'},
            {
              value: 'justify',
              name: 'Justify',
              className: 'fa fa-align-justify',
            },
          ],
        },
        {
          name: 'Decorar Texto',
          property: 'text-decoration',
          type: 'radio',
          defaults: 'none',
          list: [
            {value: 'none', name: 'None', className: 'fa fa-times'},
            {
              value: 'underline',
              name: 'underline',
              className: 'fa fa-underline',
            },
            {
              value: 'line-through',
              name: 'Line-through',
              className: 'fa fa-strikethrough',
            },
          ],
        },
        {
          name: 'Sombra de Texto',
          property: 'text-shadow',
          properties: [
            {name: 'X position', property: 'text-shadow-h'},
            {name: 'Y position', property: 'text-shadow-v'},
            {name: 'Blur', property: 'text-shadow-blur'},
            {name: 'Color', property: 'text-shadow-color'},
          ],
        },
      ],
    },
    {
      name: 'Decoración de Contenedor',
      open: false,
      buildProps: [
        'opacity',
        'border-radius',
        'border',
        'box-shadow',
        'background',
      ],
      properties: [
        {
          type: 'slider',
          name: 'Opacidad',
          property: 'opacity',
          defaults: 1,
          step: 0.01,
          max: 1,
          min: 0,
        },
        {
          property: 'border-radius',
          name: 'Curvatura de Borde',
          properties: [
            {name: 'Superior Izquierda', property: 'border-top-left-radius'},
            {name: 'Superior Derecha', property: 'border-top-right-radius'},
            {name: 'Inferior Izquierda', property: 'border-bottom-left-radius'},
            {name: 'Inferior Derecha', property: 'border-bottom-right-radius'},
          ],
        },
        {
          property: 'border',
          properties: [
            {name: 'Grosor del Borde', property: 'border-width'},
            {name: 'Estilo del Borde', property: 'border-style'},
            {name: 'Color de Borde', property: 'border-color'},
          ],
        },
        {
          property: 'box-shadow',
          name: 'Sombra de Elemento',
          properties: [
            {name: 'Posición X', property: 'box-shadow-h'},
            {name: 'Posición Y', property: 'box-shadow-v'},
            {name: 'Difuminado', property: 'box-shadow-blur'},
            {name: 'Separación', property: 'box-shadow-spread'},
            {name: 'Color', property: 'box-shadow-color'},
            {name: 'Tipo de Sombra', property: 'box-shadow-type'},
          ],
        },
        // {
        //   id: 'background-bg',
        //   name: 'Fondo de Elemento',
        //   property: 'background',
        //   type: 'bg',
        // },
      ],
    },
    {
      name: 'Posición 3D y otros',
      open: false,
      buildProps: ['transition', 'perspective', 'transform'],
      properties: [
        {
          property: 'transition',
          name: 'Transición',
          properties: [
            {name: 'Propiedad', property: 'transition-property'},
            {name: 'Duración', property: 'transition-duration'},
            {name: 'Aceleración de Animación', property: 'transition-timing-function'},
          ],
        },
        {
          property: 'transform',
          name: 'Transformación',
          properties: [
            {name: 'Rotación X', property: 'transform-rotate-x'},
            {name: 'Rotación Y', property: 'transform-rotate-y'},
            {name: 'Rotación Z', property: 'transform-rotate-z'},
            {name: 'Escalar en X', property: 'transform-scale-x'},
            {name: 'Escalar en Y', property: 'transform-scale-y'},
            {name: 'Escalar en Z', property: 'transform-scale-z'},
          ],
        },
      ],
    },
  ],
};

export const layerManager = {
  appendTo: '#layers-container',
};

export const traitManager = {
  appendTo: '#trait-container',
};

export const selectorManager = {
  appendTo: '#styles-container',
};

// paneles que se muestran en el editor
export const panels = {
  defaults: [
    {
      id: 'basic-actions',
      el: '.panel__basic-actions',
      buttons: [
        {
          id: 'visibility',
          active: true, // active by default
          // className: 'btn-toggle-borders',
          label: '<i class="fa fa-clone"></i>',
          command: 'sw-visibility', // Built-in command
          attributes: {title: 'Mostrar Límites de Elemento'},
        },
      ],
    },
    {
      id: 'editor-actions',
      el: '.panel__editor',
      buttons: [
        {
          id: 'cmd-clear',
          label: '<img style="width: 30px;" src="/topEditor/remove.png" alt="Desktop" />',
          command: 'cmd-clear',
          attributes: {title: 'Limpiar Canvas'},
        },
        {
          id: 'undo',
          label: '<img style="width: 30px;" src="/topEditor/before.png" alt="Desktop" />',
          command: 'undo',
          attributes: {title: 'Deshacer Cambio'},
        },
        {
          id: 'redo',
          label: '<img style="width: 30px;" src="/topEditor/after.png" alt="Desktop" />',
          command: 'redo',
          attributes: {title: 'Rehacer Cambio'},
        },
        /* {
          id: 'export',
          className: 'fa fa-download',
          command: 'export',
        }, */
        {
          id: 'preview-page',
          label: '<img style="width: 30px;" src="/topEditor/eye.png" alt="Desktop" />',
          command: 'preview',
          attributes: {title: 'Vista Previa'},
        },
        {
          id: 'saveDb',
          label: '<img style="width: 30px;" src="/topEditor/save.png" alt="Desktop" />',
          command: 'saveDb',
          attributes: {title: 'Guardar'},
        },
        {
          id: 'project-variables',
          label: '<img style="width: 30px;" src="/topEditor/variables_navbar.png" alt="Desktop" />',
          command: 'project-variables',
          attributes: {title: 'Variables Del Proyecto'},
        },
        {
          id: 'page-logic',
          label: '<img style="width: 30px;" src="/topEditor/flowchart.png" alt="Desktop" />',
          command: 'project-logic',
          attributes: {title: 'Lógica de Elementos'},
        },
      ],
    },
    {
      id: 'panel-devices',
      el: '.panel__devices',
      buttons: [
        {
          id: 'device-desktop',
          label: '<img style="width: 30px;" src="/topEditor/desktop.png" alt="Desktop" />',
          command: 'set-device-desktop',
          active: true,
          togglable: false,
          attributes: {title: 'Escritorio'},
        },
        {
          id: 'device-tablet',
          label: '<img style="width: 30px;" src="/topEditor/tablet.png" alt="Tablet" />',
          command: 'set-device-tablet',
          togglable: false,
          attributes: {title: 'Tablet'},
        },
        {
          id: 'device-mobile',
          label: '<img style="width: 30px;" src="/topEditor/phone.png" alt="Phone" />',
          command: 'set-device-mobile',
          togglable: false,
          attributes: {title: 'Mobile'},
        },
      ],
    },
  ],
};

// dispositivos que se pueden manejar en el editor
export const deviceManager = {
  devices: [
    {
      id: 'desktop',
      name: 'Desktop',
      width: '',
    },
    {
      id: 'tablet',
      name: 'Tablet',
      width: '770px',
      widthMedia: '992px',
    },
    {
      id: 'mobile',
      name: 'Mobile',
      width: '320px',
      widthMedia: '480px',
    },
  ],
};

// comandos que se permiten hacer dentro del editor
export const addEditorCommand = (editor, openVariables, openLogic) => {
  // Commands
  editor.Commands.add('set-device-desktop', {
    run: (editor) => editor.setDevice('Desktop'),
  });
  editor.Commands.add('set-device-mobile', {
    run: (editor) => editor.setDevice('Mobile'),
  });

  editor.Commands.add('set-device-tablet', {
    run: (editor) => editor.setDevice('Tablet'),
  });

  // Save Button
  editor.Commands.add('saveDb', {
    run: (editor, sender) => {
      // eslint-disable-next-line no-unused-expressions
      sender && sender.set('active');
      editor.store();
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      // alert('Se guardo de manera exitosa');
      toastr.clear();
      setTimeout(() => toastr.success('Se guardo de manera exitosa'), 300);
    },
  });

  // Clear Button
  editor.Commands.add('cmd-clear', {
    run: (editor) => {
      const info = '¿Limpiar/Eliminar canvas de editor?';
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm(info) === true) {
        editor.DomComponents.clear();
        editor.CssComposer.clear();
      }
    },
  });

  // Undo
  editor.Commands.add('undo', {
    run: (editor) => editor.UndoManager.undo(),
  });

  // Redo
  editor.Commands.add('redo', {
    run: (editor) => editor.UndoManager.redo(),
  });

  /* editor.Commands.add('export', {
    run: (editor) => editor.runCommand('gjs-export-zip'),
  }); */

  // Open Drawer Variable
  editor.Commands.add('project-variables', {
    run: () => {
      openVariables(true);
    },
  });

  editor.Commands.add('project-logic', {
    run: () => {
      openLogic(true);
    },
  });
};

export const storageSetting = ({
  url, token, idProject, idPage,
}) => ({
  stepsBeforeSave: 3,
  contentTypeJson: true,
  storeComponents: true,
  storeStyles: true,
  storeHtml: true,
  type: 'remote',
  storeCss: true,
  autosave: true, // Store data automatically
  autoload: true, // Autoload stored data on init
  options: {
    remote: {
      urlStore: `${url}/api/pages/editor/grape/20/update/${idProject}/${idPage}`,
      urlLoad: `${url}/api/pages/editor/load/${idProject}/${idPage}`,
      headers: {Authorization: token},
      params: {'Access-Control-Allow-Origin': 'http://localhost:3001'},
      // fetchOptions: (opts) => (opts.method === 'POST' ? {method: 'PATCH'} : {}),
      fetchOptions: '',
      onStore: (data) => data,
      onLoad: (result) => result,
      credentials: 'same-origin',
    },
  },
});

export const scripts = [
  'https://code.jquery.com/jquery-3.5.1.slim.min.js',
  'https://unpkg.com/swiper@7/swiper-bundle.min.js',
  'https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js',
  'https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js',
];

export const styles = [
  'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
  'https://unpkg.com/swiper@7/swiper-bundle.min.css',
  'https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css',
  'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css',
  'https://fonts.googleapis.com/css?family=Montserrat|WindSong|Nanum+Gothic|Didact+Gothic|Baloo+2|Italiana|Lexend:wght@100..900&display=swap',
  'https://fonts.googleapis.com/icon?family=Material+Icons',
  'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
  'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Manrope:wght@200..800&display=swap',
  'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
  'https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap',
];
