/* eslint-disable no-else-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-undef */

// eslint-disable-next-line no-unused-vars
export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  function validOnlyForCarts(target, destination, section) {
    try {
      if (destination.attributes.sluglofty.includes(section)) {
        return true;
      }
      const parentComponent = destination.parent();
      if (parentComponent !== undefined && parentComponent.attributes.sluglofty === section) {
        return true;
      } else if (parentComponent === undefined) {
        return false;
      } else {
        return validOnlyForCarts(target, parentComponent, section);
      }
    } catch (error) {
      // console.log(error);
      return false;
    }
  }

  dc.addType('cartItemHandler', {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === 'cartItemHandler') {
          return {
            type: 'cartItemHandler',
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateButtonState());
      },
      async updateButtonState() {
        try {
          if (this.model.attributes.sluglofty.includes('cartItemHandler')) {
            this.model.set({draggable: (e, d) => validOnlyForCarts(e, d, 'itemCartData')});
            this.model.set({droppable: false});
          }
        } catch (error) {
          //
        }
      },
    }),
  });
};
