/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
import {listPage} from 'api-lofty';
import {getAllReadFieldsForParams} from '../../util/databaseFieldsComponent';

export default (editor, opts = {}) => {
  // let counts = 0;
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;
  let flagOnce = true;
  function searchForReadDB(component) {
    const parentComponent = component.parent();
    if (parentComponent !== undefined && parentComponent.attributes.sluglofty.includes('ReadDBContainer')) {
      return parentComponent;
    } else if (parentComponent === undefined) {
      return null;
    } else {
      return searchForReadDB(parentComponent);
    }
  }

  function handleButtonDynamicLink(info, model) {
    const {handleDynamicLinkManager} = opts;
    handleDynamicLinkManager(info, model);
  }

  async function restoreTraitHref(model) {
    const parentComponent = model.parent();
    if (parentComponent.attributes.sluglofty.includes('ReadDBContainer')) {
      const token = opts.token;
      const idProject = opts.idProject;
      const capturedFields = await getAllReadFieldsForParams(parentComponent, token, idProject);
      capturedFields.push({value: '_id', name: '_id'});
      const fieldsParamsTraits = {
        name: 'hrefManager',
        type: 'button',
        text: 'Manejar Link',
        command: () => handleButtonDynamicLink(capturedFields, model),
      };
      model.addTrait(fieldsParamsTraits);
    } else {
      const res = searchForReadDB(parentComponent);
      if (res !== undefined) {
        const token = opts.token;
        const idProject = opts.idProject;
        const capturedFields = await getAllReadFieldsForParams(res, token, idProject);
        capturedFields.push({value: '_id', name: '_id'});
        const fieldsParamsTraits = {
          name: 'hrefManager',
          type: 'button',
          text: 'Manejar Link',
          command: () => handleButtonDynamicLink(capturedFields, model),
        };
        model.addTrait(fieldsParamsTraits);
      }
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [
          'target',
          // {
          //   name: 'href',
          //   type: 'object',
          //   changeProp: 1,
          // },
        ],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('hrefButton')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateComponent());
        this.listenTo(model, 'change:withParams', this.displayPages);
        this.listenTo(model, 'change:pageWithParams', this.searchForFields);
      },
      async updateComponent() {
        // console.log(this.model);
        try {
          if (this.model.attributes.sluglofty.includes('hrefButton')) {
            if (!this.model.getTrait('withParams')) {
              const traitAddition = {
                type: 'checkbox',
                name: 'withParams',
                label: 'Usar Local',
                changeProp: 1,
              };
              this.model.addTrait(traitAddition);
              this.model.addTrait('href');
            }
          }
          // eslint-disable-next-line max-len
          if (this.model.attributes.withParams && flagOnce) {
            const token = opts.token;
            const idProject = opts.idProject;
            const resData = await listPage({token, idProject});
            const pagesWithParams = [];
            resData.data.map((index) => {
              // if (index.url.includes(':')) {
              pagesWithParams.push({value: index.url, name: index.label});
              // }
            });
            const traitPagesWithParams = {
              type: 'select',
              name: 'pageWithParams',
              label: 'Paginas',
              changeProp: 1,
              options: pagesWithParams,
            };
            this.model.addTrait(traitPagesWithParams);
            this.model.removeTrait('href');
            if (this.model.attributes.pageWithParams) {
              this.model.getTrait('pageWithParams').set('value', this.model.attributes.pageWithParams);
              // this.searchForFields();
              if (this.model.attributes.attributes.hrefParams) {
                restoreTraitHref(this.model);
              }
            }
            flagOnce = false;
          }
        } catch (error) {
          // console.log(error);
        }
      },
      async displayPages() {
        try {
          if (this.model.attributes.sluglofty.includes('hrefButton')) {
            const wParamsTrait = this.model.getTrait('withParams');
            const paramTraitValue = wParamsTrait.attributes.value;
            if (paramTraitValue) {
              const token = opts.token;
              const idProject = opts.idProject;
              const resData = await listPage({token, idProject});
              const pagesWithParams = [];
              resData.data.map((index) => {
                // if (index.url.includes(':')) {
                pagesWithParams.push({value: index.url, name: index.label});
                // }
              });
              const traitPagesWithParams = {
                type: 'select',
                name: 'pageWithParams',
                label: 'Paginas',
                changeProp: 1,
                options: pagesWithParams,
                default: '',
              };
              this.model.addTrait(traitPagesWithParams);
              this.model.removeTrait('href');
            } else {
              this.model.removeTrait('pageWithParams');
              if (this.model.getTrait('hrefManager')) {
                this.model.removeTrait('hrefManager');
              }
              this.model.addTrait('href');
            }
          }
        } catch (error) {
          //
        }
      },
      // eslint-disable-next-line consistent-return
      async searchForFields() {
        try {
          if (!this.model.getTrait('pageWithParams').attributes.value.includes(':')) {
            this.model.removeTrait('hrefManager');
            return null;
          }
          if (this.model.attributes.sluglofty.includes('hrefButton')) {
            const parentComponent = searchForReadDB(this.model);
            if (parentComponent.attributes.sluglofty.includes('ReadDBContainer')) {
              const token = opts.token;
              const idProject = opts.idProject;
              const selectFields = await getAllReadFieldsForParams(parentComponent, token, idProject);
              const urlSelected = this.model.getTrait('pageWithParams');
              // totalParams = (urlSelected.attributes.value.match(/:/g) || []).length;
              // const optionData = captures.map((index) => ({section: index, type: 'select', data: selectFields}));
              const captures = (urlSelected.attributes.value.match(/:[a-zA-Z0-9~#@=_]*/g));
              const urlParams = captures.map((index) => ({url: index, param: '_id'}));
              selectFields.push({value: '_id', name: '_id'});
              if (this.model.getTrait('hrefManager')) {
                this.model.removeTrait('hrefManager');
              }
              //
              const fieldsParamsTraits = {
                name: 'hrefManager',
                type: 'button',
                text: 'Manejar Link',
                command: () => handleButtonDynamicLink(selectFields, this.model),
              };
              this.model.addTrait(fieldsParamsTraits);
              this.model.addAttributes({hrefParams: urlParams});
            } else {
              const res = searchForReadDB(parentComponent);
              if (res !== undefined) {
                const token = opts.token;
                const idProject = opts.idProject;
                const selectFields = await getAllReadFieldsForParams(res, token, idProject);
                const urlSelected = this.model.getTrait('pageWithParams');
                // totalParams = (urlSelected.attributes.value.match(/:/g) || []).length;
                const captures = (urlSelected.attributes.value.match(/:[a-zA-Z0-9~#@=_]*/g));
                const urlParams = captures.map((index) => ({url: index, param: '_id'}));
                selectFields.push({value: '_id', name: '_id'});
                if (this.model.getTrait('hrefManager')) {
                  this.model.removeTrait('hrefManager');
                }
                //
                const fieldsParamsTraits = {
                  name: 'hrefManager',
                  type: 'button',
                  text: 'Manejar Link',
                  command: () => handleButtonDynamicLink(selectFields, this.model),
                };
                this.model.addTrait(fieldsParamsTraits);
                this.model.addAttributes({hrefParams: urlParams});
              }
            }
            // console.log(componentData);
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
